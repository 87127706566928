.linkStyle {
    padding-right: 4vw;
    color: rgb(50, 138, 118);
    font-size: 1.09em;
    text-decoration: none;
    font-weight: 600;
}

.arrowcurly {
    height: 30px;
    position: absolute;
    z-index: 1000;
    margin-top: -50%;
    margin-left: -40%;
    animation-name: treesPopUp;
    animation-duration: 2.5s;
    animation-timing-function: linear;
    -moz-transform: scale(1, -1);
    -o-transform: scale(1, -1);
    -webkit-transform: scale(1, -1);
    transform: scale(1, -1) rotate(30deg);
}

@media only screen and (max-width: 600px) {
    .linkStyle {
        padding-right: 6vw;
        font-size: 1.3em;
        font-weight: 600;
    }
    .arrowcurly {
        margin-top: -50%;
        margin-left: -20%;
    }
}

@media only screen and (max-width: 800px) {
    .linkStyle {
        padding-right: 6vw;
    }
}

.linkStyle:hover {
    color: rgb(146, 151, 150);
}