* {
    box-sizing: border-box;
}

.timelineContainer {
    background-color: #f1f1f1;
    height: 100%;
}

.experienceContainer {
    background-color: #f1f1f1;
    height: auto;
    width: 100%;
    color: rgb(50, 138, 118);
}

/* The actual timeline (the vertical ruler) */

.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */

.timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}

/* Container around content */

.container {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 45%;
}

/* The circles on the timeline */

.container::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: white;
    border: 4px solid rgb(50, 138, 118);
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}

/* Place the container to the left */

.left {
    left: 5%;
}

/* Place the container to the right */

.right {
    left: 50%;
}

/* Add arrows to the left container (pointing right) */

.left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */

.right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */

.right::after {
    left: -16px;
}

/* The actual content */

.content {
    padding: 20px 30px;
    background-color: white;
    position: relative;
    border-radius: 6px;
    box-shadow: 1px 2px 4px grey;
}

.content p {
    font-weight: 400;
}

/* Media queries - Responsive timeline on screens less than 600px wide */

@media screen and (max-width: 600px) {
    /* Place the timelime to the left */
    .timeline::after {
        left: 31px;
    }
    /* Full-width containers */
    .container {
        width: 100%;
        padding-left: 70px;
        padding-right: 25px;
    }
    /* Make sure that all arrows are pointing leftwards */
    .container::before {
        left: 60px;
        border: medium solid white;
        border-width: 10px 10px 10px 0;
        border-color: transparent white transparent transparent;
    }
    /* Make sure all circles are at the same spot */
    .left::after, .right::after {
        left: 15px;
    }
    /* Make all right containers behave like the left ones */
    .right {
        left: 0%;
    }
    .left {
        left: 0%;
    }
    .content {
        box-shadow: 2px 2px 10px grey;
    }
}

.cardIcon {
    padding: 0;
    float: left;
    margin-right: 20px;
}

.mySkilsContainer {
    background-color: white;
    height: auto;
    padding: 50px;
}

.mySkilGrid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.chipStyle {
    background-color: #f1f1f1 !important;
    color: rgb(103, 158, 145) !important;
    font-size: 1.1em !important;
    padding: 2% !important;
    margin: 20px !important;
    margin-right: 50px !important;
    min-width: 100px !important;
    font-weight: 600 !important;
    /* box-shadow: 3px 3px 5px grey !important; */
}

@media screen and (max-width: 600px) {
    .chipStyle {
        margin-right: 20px !important;
        font-size: 1em !important;
        height: 42px !important;
    }
    .mySkilsContainer {
        padding-left: 0;
        padding-right: 0;
    }
}