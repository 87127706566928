
.square {
    width: 60%;
    height: 100%;
    background-color: #45b29a;
    line-height: 10em;
    text-align: center;
    color: #FFF;
    margin: auto;
    font-size: 1.3em;
    font-weight: 700;
    text-shadow: 1px 1px rgb(53, 52, 52);
}

.square.x-axis {
    animation: x-axis-animation 2s ease-in-out 0s infinite alternate;
}

@keyframes x-axis-animation {
    from {
        transform: translateX(35%)
    }
    to {
        transform: translateX(-35%)
    }
}

.x-axis-text {
    animation: x-axis-text-animation 2s ease-in-out 0s infinite alternate;
}

@keyframes x-axis-text-animation {
    from {
        transform: translateX(-35%)
    }
    to {
        transform: translateX(35%)
    }
}

.square.y-axis {
    animation: y-axis-animation 0.8s ease-in-out 0s infinite alternate;
}

@keyframes y-axis-animation {
    from {
        transform: translateY(0.8em)
    }
    to {
        transform: translateY(-0.8em)
    }
}

.y-axis-text {
    animation: y-axis-text-animation 0.8s ease-in-out 0s infinite alternate;
}

@keyframes y-axis-text-animation {
    from {
        transform: translateY(-0.8em)
    }
    to {
        transform: translateY(0.8em)
    }
}

.square.z-axis {
    animation: z-axis-animation 2s ease-in-out 0s infinite alternate; 
}



@keyframes z-axis-animation {
    from {
        transform: translateZ(0.8em)
    }
    to {
        transform: translateZ(-0.8em)
    }
}

.z-axis-text {
    animation: z-axis-text-animation 2s ease-in-out 0s infinite alternate;
}

@keyframes z-axis-text-animation {
    from {
        transform: translateZ(-0.8em)
    }
    to {
        transform: translateZ(0.8em)
    }
}