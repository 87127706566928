/***************************/

/**********Grid*************/

/***************************/

.topLogo {
    height: 120px;
    background-color: #f1f1f1;
}

.cloudLeft {
    width: 120px;
    height: 100px;
    position: absolute;
    margin: auto;
    top: 150x;
    left: 20vw;
    right: 0;
    bottom: 0;
    z-index: 2;
    opacity: 0.5;
}

.cloudRight {
    width: 120px;
    height: 100px;
    position: absolute;
    margin: auto;
    top: -180px;
    left: -20vw;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    z-index: 50;
}

.bottom {
    height: 300px;
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    background-color: #f1f1f1;
}

.secondTop {
    height: 1100px;
}

@media only screen and (max-width: 600px) {
    .secondTop {
        height: 1300px;
    }
}

.footer {
    height: 120px;
    background-color: #f1f1f1;
}

/***************************/

/********Centre Piece*******/

/***************************/

.maintree {
    height: 300px;
    z-index: 4;
    position: absolute;
    margin-top: 0px;
    margin-left: 0px;
    animation-name: treesPopUp;
    animation-duration: 2.5s;
    animation-timing-function: linear;
    display: none;
}

.maintree2 {
    height: 200px;
    z-index: 4;
    position: absolute;
    margin-top: 100px;
    margin-left: -130px;
    animation-name: treesPopUp;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    display: none;
}

.firstMountain {
    margin: auto;
    margin-top: 100px;
    margin-left: 65%;
    animation-name: mountainDrop;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    display: block;
}

.secondMountain {
    margin: auto;
    margin-top: 50px;
    /* margin-left: 5%; */
    animation-name: mountainDrop;
    animation-duration: 1s;
    animation-timing-function: linear;
    display: block;
}

.thirdMountain {
    margin: auto;
    margin-left: -60%;
    margin-top: 100px;
    animation-name: mountainDrop;
    animation-duration: 1s;
    animation-timing-function: linear;
    display: block;
}

.house2 {
    height: 55px;
    width: 50px;
    z-index: 4;
    position: absolute;
    margin-top: 70px;
    margin-left: 0px;
    display: block;
}

.house1 {
    height: 40px;
    width: 40px;
    margin-top: 16px;
    margin-left: 192px;
    position: absolute;
    display: block;
}

.treeA {
    height: 65px;
    width: 60px;
    position: absolute;
    margin-top: 100px;
    margin-left: -62px;
    z-index: 4;
    display: block;
}

.treeB {
    height: 65px;
    width: 50px;
    position: absolute;
    margin-top: 138px;
    margin-left: -96px;
    z-index: 4;
    display: block;
}

.treeC {
    height: 75px;
    width: 70px;
    position: absolute;
    margin-top: 175px;
    margin-left: -146px;
    z-index: 4;
    display: block;
}

.treeD {
    height: 30px;
    width: 25px;
    position: absolute;
    margin-top: 38px;
    margin-left: 244px;
    z-index: 4;
    filter: contrast(100%);
    display: block;
}

.treeE {
    height: 40px;
    width: 30px;
    position: absolute;
    margin-top: 65px;
    margin-left: 130px;
    z-index: 4;
    filter: contrast(100%);
    display: block;
}

.panel {
    height: 35px;
    width: 35px;
    position: absolute;
    margin-top: 75px;
    margin-left: 352px;
    z-index: 4;
    display: block;
}

.flower {
    height: 20px;
    z-index: 4;
    width: 20px;
    position: absolute;
    margin-top: 280px;
    margin-left: 170px;
}

.fly {
    height: 20px;
    z-index: 4;
    width: 20px;
    position: absolute;
    margin-top: 250px;
    margin-left: 130px;
}

@keyframes mountainDrop {
    0% {
        margin-top: 50px;
    }
    50% {
        margin-top: 80px;
    }
    100% {}
}

@keyframes treesPopUp {
    0% {
        opacity: 0.2;
    }
    25% {
        opacity: 0.4;
    }
    50% {
        opacity: 0.6;
    }
    75% {
        opacity: 0.8;
    }
    100% {
        opacity: 0.9;
    }
}

/***************************/

/*********Center text*********/

/***************************/

.centerPiece {
    width: 220px;
    height: 150px;
    position: absolute;
    margin: auto;
    top: 20px;
    left: -12px;
    right: 0;
    bottom: 0;
}

.centerPiece p {
    color: rgb(50, 138, 118);
    font-size: 1.8em;
    letter-spacing: .15em;
}

.typewriter p {
    color: rgb(50, 138, 118);
    font-size: 2em;
    font-family: 'Jura', sans-serif;
    overflow: hidden;
    /* Ensures the content is not revealed until the animation */
    border-right: .15em solid rgb(69, 178, 154);
    /* The typwriter cursor */
    white-space: nowrap;
    /* Keeps the content on a single line */
    margin: 0 auto;
    /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em;
    /* Adjust as needed */
    animation: typing 4s steps(50, end) infinite, blink-caret 0.5s step-end infinite;
}

/* The typing effect */

@keyframes typing {
    from {
        width: 0
    }
    to {
        width: 100%
    }
}

/* The typewriter cursor effect */

@keyframes blink-caret {
    from, to {
        border-color: transparent
    }
    50% {
        border-color: rgb(69, 178, 154)
    }
}

.cursor {
    display: inline-block;
    position: relative;
}

.cursor::after {
    content: "";
    margin: auto;
    position: absolute;
    right: -4px;
    top: -3px;
    width: 1px;
    height: 100%;
    background-color: rgb(69, 178, 154);
    animation: cursor-animation 1s step-end infinite;
}

@keyframes cursor-animation {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/***************************/

/*********Flying bird*********/

/***************************/

.bird {
    background-image: url(../../images/bird-cells.svg);
    background-size: auto 100%;
    width: 88px;
    height: 125px;
    will-change: background-position;
    -webkit-animation-name: fly-cycle;
    animation-name: fly-cycle;
    -webkit-animation-timing-function: steps(10);
    animation-timing-function: steps(10);
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.bird--one {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.bird--two {
    -webkit-animation-duration: 0.9s;
    animation-duration: 0.9s;
    -webkit-animation-delay: -0.75s;
    animation-delay: -0.75s;
}

.bird--three {
    -webkit-animation-duration: 1.25s;
    animation-duration: 1.25s;
    -webkit-animation-delay: -0.25s;
    animation-delay: -0.25s;
}

.bird--four {
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.bird-container {
    position: absolute;
    top: 20%;
    -webkit-transform: scale(0);
    transform: scale(0);
    will-change: transform;
    -webkit-animation-name: fly-right-one;
    animation-name: fly-right-one;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: 1;
}

.bird-container--one {
    -webkit-animation-duration: 15s;
    animation-duration: 15s;
    -webkit-animation-delay: 0;
    animation-delay: 3.5s;
    z-index: 10;
}

.bird-container--two {
    -webkit-animation-duration: 16s;
    animation-duration: 16s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    z-index: 10;
}

.bird-container--three {
    -webkit-animation-duration: 14.6s;
    animation-duration: 14.6s;
    -webkit-animation-delay: 9.5s;
    animation-delay: 9.5s;
    z-index: 10;
}

.bird-container--four {
    -webkit-animation-duration: 16s;
    animation-duration: 16s;
    -webkit-animation-delay: 10.25s;
    animation-delay: 10.25s;
    z-index: 10;
}

@media only screen and (max-width: 600px) {
    .bird-container--one {
        animation-duration: 8s;
    }
    .bird-container--two {
        animation-duration: 9s;
    }
    .bird-container--three {
        animation-duration: 8.6s;
    }
    .bird-container--four {
        animation-duration: 11s;
    }
}

@keyframes fly-cycle {
    100% {
        background-position: -900px 0;
    }
}

@keyframes fly-right-one {
    0% {
        left: 0%;
        -webkit-transform: scale(0.3);
        transform: scale(0.3);
    }
    10% {
        left: 10%;
        -webkit-transform: translateY(2vh) scale(0.4);
        transform: translateY(2vh) scale(0.4);
    }
    20% {
        left: 20%;
        -webkit-transform: translateY(0vh) scale(0.5);
        transform: translateY(0vh) scale(0.5);
    }
    30% {
        left: 30%;
        -webkit-transform: translateY(4vh) scale(0.6);
        transform: translateY(4vh) scale(0.6);
    }
    40% {
        left: 40%;
        -webkit-transform: translateY(2vh) scale(0.6);
        transform: translateY(2vh) scale(0.6);
    }
    50% {
        left: 50%;
        -webkit-transform: translateY(0vh) scale(0.6);
        transform: translateY(0vh) scale(0.6);
    }
    60% {
        left: 60%;
        -webkit-transform: translateY(0vh) scale(0.6);
        transform: translateY(0vh) scale(0.6);
    }
    70% {
        left: 70%;
        -webkit-transform: translateY(0vh) scale(0.6);
        transform: translateY(0vh) scale(0.6);
    }
    80% {
        left: 80%;
        z-index: 0;
        display: block;
        -webkit-transform: translateY(0vh) scale(0.6);
        transform: translateY(0vh) scale(0.6);
    }
    90% {
        left: 60%;
        z-index: 0;
        display: block;
        -webkit-transform: translateY(0vh) scale(0.6);
        transform: translateY(0vh) scale(0.6);
    }
    100% {
        left: 60%;
        z-index: 0;
        display: block;
        -webkit-transform: translateY(0vh) scale(0.6);
        transform: translateY(0vh) scale(0.6);
    }
}

.snowContainer {
    height: 102vh;
    width: 100vw;
    position: absolute;
    z-index: 20;
}

@media only screen and (max-width: 1000px) {
    .snowContainer {
        height: 130vh;
    }
}

@media only screen and (max-width: 600px) {
    .snowContainer {
        height: 80vh;
    }
}