.outerDivAbooutMe {
    width: 100%;
    height: 500px;
    text-align: center;
    position: absolute;
}

.innerDivAbooutMe {
    width: 50%;
    height: 70%;
    position: absolute;
    margin: auto;
    top: -3%;
    left: 0;
    right: 0;
    bottom: 0;
}

@media only screen and (max-width: 600px) {
    .innerDivAbooutMe {
        width: 95%;
    }
}

.imgAboutMe {
    height: 200px;
    width: 195px;
}

.textAboutMe {
    color: rgb(50, 138, 118);
    font-size: 1.2em;
    font-weight: 600;
}

@media only screen and (max-width: 600px) {
    .textAboutMe {
        font-size: 1.1em;
    }
}

.mainAboutMe {
    padding: 50px 50px 0px 50px;
}

@media only screen and (max-width: 600px) {
    .mainAboutMe {
        padding: 50px 10px 0px 10px;
    }
}

/***********************************************************/

/* About section */

/***********************************************************/

/**********************************************/

/***************Front end info******************/

/**********************************************/

.outerDivfrondEndInfo {
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    /* background-color: rgb(139, 133, 133); */
}

.innerDivfrondEndInfo {
    width: 60%;
    height: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    left: 40%;
    right: 0;
    bottom: 0;
    /* background-color: whitesmoke; */
}

@media only screen and (max-width: 600px) {
    .innerDivfrondEndInfo {
        width: 85%;
        left: 0;
    }
}

.textfrondEndInfo {
    position: absolute;
    margin: auto;
    top: -100px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    background-color: white;
}

@media only screen and (max-width: 600px) {
    .textfrondEndInfo {
        padding: 0 0 0 0;
        top: -50px;
    }
}

.outerDivfrontEndTools {
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    /* background-color: rgb(240, 21, 21); */
}

@media only screen and (max-width: 600px) {
    .outerDivfrontEndTools {}
}

.innerDivfrontEndTools {
    width: 120px;
    height: 120px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 40%;
    bottom: 0;
    background-color: #f1f1f1;
    ;
    border-radius: 50%;
}

@media only screen and (max-width: 600px) {
    .innerDivfrontEndTools {
        width: 140px;
        height: 140px;
        right: 0;
    }
}

.imgfrontEndTools {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

/**********************************************/

/***************Back end info******************/

/**********************************************/

.outerDivbackEndInfo {
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    /* background-color: rgb(139, 133, 133); */
    /* margin-top: -20px; */
}

.innerDivbackEndInfo {
    width: 60%;
    height: 80%;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 40%;
    bottom: 0;
}

@media only screen and (max-width: 600px) {
    .innerDivbackEndInfo {
        width: 85%;
        right: 0;
    }
}

.textbackEndInfo {
    position: absolute;
    margin: auto;
    top: -100px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    background-color: white;
}

@media only screen and (max-width: 600px) {
    .textbackEndInfo {
        padding: 0 0 0 0;
        top: -60px;
    }
}

.outerDivbackEndTools {
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    /* background-color: rgb(240, 21, 21); */
    /* margin-top: -20px; */
}

.innerDivbackEndTools {
    width: 120px;
    height: 120px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 40%;
    right: 0;
    bottom: 0;
    background-color: #f1f1f1;
    border-radius: 50%;
}

hr {
    position: relative;
    margin-top: 13%;
    width: 10%;
    border: 5px dashed rgb(50, 138, 118);
    border-radius: 5px;
    z-index: 5;
}

@media only screen and (max-width: 600px) {
    .innerDivbackEndTools {
        width: 140px;
        height: 140px;
        left: 0;
    }
    hr {
        margin-top: 16%;
    }
}

@media only screen and (min-width: 1800px) {
    hr {
        display: none;
    }
}